<template>
  <div class="_100vh pa-3 d-flex align-center ">
    <v-row class="z-2 _100top">
      <v-col cols="12" md="6" class="d-flex flex-column justify-center pl-10">
        <h1 class="purple--text">{{ title }}</h1>
        <p class="grey--text">{{ tagline ? tagline : "" }}</p>
      </v-col>
      <v-col cols="12" md="6" class="pa-0 d-flex justify-center">
        <img :src="cover" alt="" class="_hero-cover" />
      </v-col>
    </v-row>
    <section class="wave">
      <svg
        width="800"
        height="581"
        viewBox="0 0 600 381"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g id="Layer x0020 1">
          <g id="2115980870688">
            <path
              id="wave-3"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M192.935 243.251C364.784 210.506 337.68 341.211 497.932 380.577H600.218V0H54.2919C-47.5524 87.3724 -8.72571 281.676 192.935 243.25V243.251Z"
              fill="#F3E5F5"
            />
            <path
              id="wave-2"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M600.218 380.577V0.000457764H256.369C173.964 62.2087 138.974 151.85 200.493 189.944C242.828 216.159 303.665 201.662 358.449 224.478C423.107 251.406 421.663 325.113 445.497 380.577H600.218V380.577Z"
              fill="#E1BEE7"
              fill-opacity="0.34902"
            />
            <path
              id="wave-1"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M503.552 270.993C533.977 279.45 576.341 276.983 600.218 256.863V0.000457764H129.149C99.599 26.4368 108.447 85.7876 137.295 108.543C170.801 134.972 203.563 133.417 248.173 129.138C288.684 125.252 359.667 114.388 397.14 171.9C434.614 229.411 473.126 262.536 503.552 270.993Z"
              fill="#CE93D8"
              fill-opacity="0.14902"
            />
          </g>
        </g>
      </svg>
    </section>
    <div></div>
  </div>
</template>

<script>
export default {
  name: "HeroCover",
  props: ["title", "cover", "tagline"]
};
</script>

<style scoped>
#wave-3 {
  animation: waveone infinite ease-in-out alternate 4s;
  transform-origin: bottom;
  transform-box: fill-box;
  animation-delay: 1s;
}
#wave-2 {
  animation: waveone infinite ease-in-out alternate 4s;
  transform-origin: bottom;
  transform-box: fill-box;
  animation-delay: 0.5s;
}
#wave-1 {
  animation: waveone infinite ease-in-out alternate 4s;
  transform-origin: bottom;
  transform-box: fill-box;
}

@keyframes waveone {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(5%);
  }
}
</style>
