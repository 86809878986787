<template>
  <div>
    <v-dialog v-model="dialogAge" persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar flat dense
          ><h3>Ada yang bisa kami bantu?</h3>
          <v-spacer></v-spacer
          ><v-btn icon @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <div class="d-flex flex-column align-center pa-3">
          <h3 class="text-center purple--text">
            Rentang umur psikolog yang Anda inginkan?
          </h3>
          <div class="d-flex flex-wrap">
            <div v-for="n in age" :key="n.id1" class="ma-1">
              <v-btn
                rounded
                :color="selected.name == n.name ? 'blue' : 'white'"
                :dark="selected.name == n.name ? true : false"
                @click="selected = n"
              >
                {{ n.name }}
              </v-btn>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue" dark @click="select()">
            Next
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "selectAge",
  props: ["dialogAge"],
  data() {
    return {
      selected: "",
      dataAge: null,
      age: [
        {
          name: "Usia 20 - 40 tahun",
          from: 20,
          to: 40
        },
        {
          name: "> 40 tahun",
          from: 40,
          to: 200
        }
      ]
    };
  },
  methods: {
    select() {
      this.$emit("next", "religion", this.selected);
    }
  }
};
</script>

<style></style>
