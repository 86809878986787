<template>
  <div>
    <v-dialog v-model="dialogReligion" persistent max-width="500px">
      <v-card class="radius-card">
        <v-toolbar flat dense
          ><h3>Ada yang bisa kami bantu?</h3>
          <v-spacer></v-spacer
          ><v-btn icon @click="$emit('false')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <div class="d-flex flex-column align-center pa-3">
          <h3 class="text-center purple--text">Psikolog yang Anda inginkan?</h3>
          <div class="d-flex flex-wrap">
            <div v-for="n in religion" :key="n" class="ma-1">
              <v-btn
                rounded
                :color="selected == n ? 'blue' : 'white'"
                :dark="selected == n ? true : false"
                @click="selected = n"
              >
                {{ n ? n : "Keduanya" }}
              </v-btn>
            </div>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="selected != null">
            <v-btn color="blue" dark @click="next(selected)">
              Next
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "selectReligion",
  props: ["dialogReligion"],
  data() {
    return {
      selected: null,
      religion: [
        "Islam",
        "Protestan",
        "Katholik",
        "Hindu",
        "Buddha",
        "Konghucu",
        "Lainya"
      ]
    };
  },
  methods: {
    next(item) {
      const selected = item.toLowerCase();
      this.$emit("next", "service", selected);
    }
  }
};
</script>

<style></style>
