<template>
  <div>
    <Nav />
    <HeroCover
      v-bind:title="title"
      v-bind:cover="require('../../assets/img/hero_cover.png')"
    />
    <div class="px-3 purple sticky_elmnt">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            solo
            :background-color="isActive == true ? '#fff' : '#F3E5F5'"
            flat
            label="Cari Nama Psikolog"
            rounded
            append-icon="mdi-magnify"
            hide-details
            required
            v-model="find"
            @keyup="fetchPsycholog(find === '' ? false : true)"
            @focus="isActive = true"
            @blur="isActive = false"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="fields">
          <v-select
            solo
            :background-color="isActive == true ? '#fff' : '#F3E5F5'"
            rounded
            hide-details="auto"
            item-text="nama"
            item-value="id"
            :items="fields.data"
            label="Cari Berdasarkan Bidang"
            v-model="selectedTopic"
            @change="fetchPsycholog(selectedTopic == '' ? false : true)"
          ></v-select>
        </v-col>
      </v-row>
      <section>
        <div class="d-flex justify-center mt-2">
          <div class="d-flex justify-center flex-wrap" style="width: 70%">
            <!-- <v-btn
              class="ma-2"
              color="#F3E5F5"
              rounded
              v-for="(chip, idx) in category"
              :key="idx"
            >
              {{ chip.name }}
            </v-btn> -->
            <v-btn class="ma-2" color="blue" dark @click="needHelp = true">
              <v-icon>mdi-help-circle-outline</v-icon> Butuh Bantuan?
            </v-btn>

            <v-btn class="ma-2" color="blue" dark @click="resetFind">
              <v-icon>mdi-refresh</v-icon>
              Refresh
            </v-btn>
          </div>
          <Needhelp
            v-bind:dialogHelp="needHelp"
            @false="closeDialog(1)"
            @next="nextStep"
          />
          <SelectTopic
            v-bind:dialogTopic="topic"
            @false="closeDialog(2)"
            @next="nextStep"
          />
          <SelectGender
            v-bind:dialogGender="gender"
            @false="closeDialog(3)"
            @next="nextStep"
          />
          <SelectAge
            v-bind:dialogAge="age"
            @false="closeDialog(4)"
            @next="nextStep"
          />
          <SelectReligion
            v-bind:dialogReligion="religi"
            @false="closeDialog(5)"
            @next="nextStep"
          />
          <SelectService
            v-bind:dialogService="service"
            @false="closeDialog(6)"
            @next="nextStep"
          />
        </div>
      </section>
    </div>
    <div class="temp-down">
      <v-btn
        color="red"
        id="updown"
        small
        depressed
        fab
        dark
        content="Scroll Down"
        @click="scrollDown"
        v-tippy="{
          arrow: true,
          arrowType: 'round',
          animation: 'fade',
          theme: 'light'
        }"
        ><v-icon>mdi-arrow-down</v-icon></v-btn
      >
    </div>
    <div class="d-flex align-center flex-column _wave pa-3">
      <div class="d-flex justify-center" v-if="loading">
        <v-progress-circular
          indeterminate
          width="7"
          size="50"
          color="purple"
        ></v-progress-circular>
      </div>
      <div class="_90w" v-if="psycholog">
        <v-row>
          <v-col
            cols="12"
            md="3"
            lg="3"
            sm="4"
            xs="6"
            v-for="item in psycholog"
            :key="item.id"
          >
            <v-card class="radius-card" style="overflow: hidden" height="310">
              <div
                :class="
                  item.bidang_id == 1
                    ? 'a__'
                    : item.bidang_id == 2
                    ? 'c__'
                    : 'b__'
                "
                style="height: 100px; border-radius: 0"
              ></div>
              <div class="d-flex justify-center img__temp">
                <v-avatar size="130" style="border: 5px white solid">
                  <img
                    :src="
                      item.photo_profile
                        ? `${env}/upload/photo_profile/${item.id}/${item.photo_profile}`
                        : dummy
                    "
                    alt=""
                    class="img-fit"
                  />
                </v-avatar>
              </div>
              <div class="px-3 pb-3">
                <div class="d-flex flex-column align-center">
                  <h2 class="text-center" v-snip="1">
                    {{ item.nama_lengkap }}
                  </h2>
                  <div class="pa-1">
                    <section
                      v-for="(relation, idx) in item.relationships"
                      :key="`relasi-ke-${idx}`"
                    >
                      <section class="text-center" v-if="relation.bidang">
                        <small>{{ relation.bidang.data.nama }}</small>
                      </section>
                      <section v-if="relation.kota1">
                        <small class="text-center" v-snip="1"
                          >{{ relation.kota1.data.nama_kota }},
                          {{
                            relation.kota1.data.data_provinsi.nama_provinsi
                          }}</small
                        >
                      </section>
                    </section>
                  </div>
                  <router-link :to="`/${item.id}`">
                    <v-btn class="ma-2 blue" dark>View Profile</v-btn>
                  </router-link>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
        <div v-if="psycholog" v-observe-visibility="handleScroll"></div>

        <div v-if="psycholog" class="d-flex justify-center mt-5" id="psycho">
          <div
            v-if="!psycholog.length"
            class="d-flex justify-center flex-column"
          >
            <img
              src="../../assets/img/404.svg"
              height="200px"
              alt="nothing to do here"
            />
            <h1 class="text-center">Maaf! Kami tidak menemukanya!</h1>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Nav from "../../components/General/Nav.vue";
import Needhelp from "../../components/Public/Modal/Needhelp.vue";
import SelectAge from "../../components/Public/Modal/selectAge.vue";
import SelectGender from "../../components/Public/Modal/selectGender.vue";
import SelectReligion from "../../components/Public/Modal/selectReligion.vue";
import SelectService from "../../components/Public/Modal/selectService.vue";
import SelectTopic from "../../components/Public/Modal/selectTopic.vue";
import HeroCover from "../../components/_base/heroCover.vue";
export default {
  name: "findPsycholog",
  data() {
    return {
      isActive: false,
      find: "",
      // v-model modal
      needHelp: true,
      topic: false,
      gender: false,
      age: false,
      religi: false,
      service: false,

      // value sortir
      selectedTopic: "",
      selectedGender: "",
      selectedAge: "",
      selectedService: "",
      ageFrom: "",
      ageTo: "",

      //
      currentPage: 1,
      limit: 8,
      title: "Temukan Psikolog yang tepat untuk menyelesaikan permasalahanmu.",
      loading: false,
      field: [
        { state: "Buddha", val: "buddha" },
        { state: "Hindu", val: "hindu" },
        { state: "Islam", val: "islam" }
      ],
      category: "",
      isFinding: false
    };
  },
  computed: {
    ...mapState({
      psycholog: state => state.allPsychologForPublic,
      last: state => state.last_page_allPsychologForPublic,
      env: state => state.API_URL,
      fields: state => state.fields.fields,
      dummy: state => state.dummy
    })
  },
  components: {
    HeroCover,
    Needhelp,
    SelectTopic,
    SelectGender,
    SelectAge,
    SelectService,
    Nav,
    SelectReligion
  },
  mounted() {
    this.fetchPsycholog(false);
    this.$store.dispatch("fields/listFields");
  },
  methods: {
    resetFind() {
      this.selectedTopic = "";
      this.selectedGender = "";
      this.selectedService = "";
      this.ageFrom = "";
      this.ageTo = "";
      this.currentPage = 1;
      this.limit = 8;
      this.find = "";
      this.fetchPsycholog(false);
    },
    fetchPsycholog(status) {
      this.isFinding = status;
      if (this.isFinding) {
        this.currentPage = 1;
      }

      this.loading = true;
      let data = {
        bidang: this.selectedTopic,
        gender: this.selectedGender,
        service: this.selectedService,
        from: this.ageFrom,
        to: this.ageTo,
        nama: this.find,
        page: this.currentPage,
        limit: this.limit
      };
      this.$store.dispatch("allPsycholog", data).then(data => {
        if (data) {
          this.loading = false;
        }
      });
    },

    handleScroll(isVisible) {
      if (isVisible) {
        if (this.isFinding == false) {
          if (this.currentPage <= this.last) {
            this.currentPage++;
            this.fetchPsycholog(false);
          }
        }
      }
    },

    nextStep(step, val) {
      switch (step) {
        case "topic":
          this.topic = true;
          this.closeDialog(1);
          break;
        case "gender":
          this.gender = true;
          this.closeDialog(2);
          this.selectedTopic = val;
          break;
        case "age":
          this.age = true;
          this.closeDialog(3);
          this.selectedGender = val;
          break;
        case "religion":
          this.religi = true;
          this.closeDialog(4);
          this.ageFrom = val.from;
          this.ageTo = val.to;
          break;
        case "service":
          this.service = true;
          this.closeDialog(5);
          this.selecteReligion = val;
          break;
        case "finish":
          this.service = true;
          this.closeDialog(6);
          this.selectedService = val;
          this.fetchPsycholog(true);
          this.scrollTo();
          break;
      }
    },

    scrollTo() {
      document.getElementById(`psycho`).scrollIntoView();
    },
    scrollDown() {
      window.scrollBy(0, 500);
    },
    closeDialog(q) {
      switch (q) {
        case 1:
          this.needHelp = false;
          break;
        case 2:
          this.topic = false;
          break;
        case 3:
          this.gender = false;
          break;
        case 4:
          this.age = false;
          break;
        case 5:
          this.religi = false;
          break;
        case 6:
          this.service = false;
          break;
      }
    },

    toView(item) {
      console.log(item);
      this.$router.push(`/${item.id}`);
    }
  }
};
</script>
<style scoped>
.cards {
  display: flex;
  justify-content: space-between;
  width: 450px;
  height: max-content;
}
.radius {
  height: 130px;
  width: 130px;
  border-radius: 50% !important;
}
p {
  margin-bottom: 0;
}
._90w {
  width: 90%;
}
.img__temp {
  margin-top: -70px;
}
.temp-down {
  z-index: 2;
  position: fixed;
  right: 10px;
  bottom: 30px;
}
.sticky_elmnt {
  z-index: 2;
  position: -webkit-sticky;
  position: sticky;
  top: 90px;
  background: white;
}
#updown {
  animation: towewew infinite ease-in-out alternate 0.8s;
  animation-delay: 0.5s;
}
@keyframes towewew {
  0% {
    transform: translate(0);
  }
  100% {
    transform: translate(0, 50%);
  }
}
</style>
