<template>
  <div>
    <v-dialog v-model="dialogHelp" persistent max-width="500px">
      <v-card class="radius-card d-flex flex-column align-center pa-3">
        <img
          src="../../../assets/img/Icon01.svg"
          alt="img-search"
          class="mb-3"
        />
        <h3 class="text-center purple--text">
          Butuh bantuan untuk mencari psikolog?
        </h3>
        <div class="d-flex">
          <v-btn class="ma-2" dark color="blue" @click="$emit('next', step)"
            >Ya</v-btn
          >
          <v-btn class="ma-2" color="error" @click="$emit('false')"
            >Tidak</v-btn
          >
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "needHelp",
  props: ["dialogHelp"],
  data() {
    return {
      step: "topic"
    };
  }
};
</script>

<style></style>
